import React, { useEffect, useState } from "react";
import { Container, Row, Col, Input, Label, Button, Spinner } from "reactstrap";
import { useLocation, Link, useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";

import logoLight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo.png";
import { Field, Formik, Form } from "formik";
import { SignInScheme } from "../../schemas/SignInSchema";
import { ILogin } from "../../interfaces/ILogin";
import { Urls } from "../../routes/Urls";
import UserAPI from "../../services/UserAPI";
import resolveBaseUrl from "../../services";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { getStorableUser, isLoggedIn, setCurrentTenant } from "../../utils/Helpers";
import { values } from "lodash";
import { AxiosResponse } from "axios";

const LoginPage = () => {

  const request = new ApiCore();
  const baseUrl = resolveBaseUrl()
  const credentials: ILogin = {
    email: "",
    password: "",
    otp: ""
  };

  const params = new URLSearchParams(useLocation().search);
  const code = params.get("code");

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [showotp, setShowotp] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn()) {
      loadHomePage();
    } else if (code) {
      redeemCode(code);
    }
  })

  const loadHomePage = () => {
    window.location.href = Urls.home;
  }

  const passwordLogin = (values: any) => {
    request.create(Endpoints.login, { username: values.email,password: values.password })
      .then((response: any) => {

        let { data, headers } = response

        localStorage.setItem("__credify__access__token__", data.accessToken)
        localStorage.setItem("__credify__user__", JSON.stringify({
          id: data.id,
          name: data.name,
          roles: data.roles,
          username: data.username,
          email: data.email
        }))

        setLoading(false);
        loadHomePage();
      })
      .catch(error => {
        if (error == "Unauthorized") {
          setErrorMessage("Invalid email or password");
        } else {
          setErrorMessage(error)
        }
        setLoading(false)
      });
  };

  const otpLogin = (values: any) => {
    request.create(Endpoints.otpLogin, { phone: values.email, otp: values.otp })
      .then((response: any) => {

        let { data } = response;

        localStorage.setItem("__credify__access__token__", data.accessToken)
        localStorage.setItem("__credify__user__", JSON.stringify({
          id: data.id,
          name: data.name,
          roles: data.roles,
          username: data.username,
          email: data.email
        }))

        setLoading(false);
        loadHomePage();
      })
      .catch(error => {
        if (error == "Unauthorized") {
          setErrorMessage("Invalid credentials");
        } else {
          setErrorMessage(error)
        }
        setLoading(false)
      });
  };

  const redeemCode = (mCode: string) => {
    setLoading(true);
    request.create(Endpoints.loginRedeem, { code: mCode })
      .then((response: any) => {

        let { data, headers } = response

        localStorage.setItem("__treda__access__token__", headers.authorization)
        localStorage.setItem("__treda__user__", JSON.stringify({
          id: data.id,
          avatar: `${baseUrl}/${data.path}`,
          name: data.name,
          is_admin: data.isadmin,
          admin: data.admin,
          email: data.email,
          tenants: data.tenants.map((tenant: any) => ({
            id: tenant.id,
            code: tenant.tenantcode,
            tenantcode: tenant.tenantcode,
            name: tenant.name,
            logo: tenant.logo_path,
            category: tenant.category,
            schooltype: tenant.schooltype
          }))
        }))
        setCurrentTenant(data.tenants[0]);
        fetchProfile(data.tenants[0], headers.authorization);
      })
      .catch(error => {
        if (error == "Unauthorized") {
          setErrorMessage("Invalid code");
        } else {
          setErrorMessage(error)
        }
        setLoading(false)
      });
  };

  const fetchOtp = (phone: string) => {
    setLoading(true);
    request.create(Endpoints.otp, { phone: phone })
      .then((response: any) => {
        let { data, headers } = response;
        setShowotp(true);
        setShowpassword(false);
        setLoading(false);
      })
      .catch(error => {
        if (error == "Unauthorized") {
          setErrorMessage("Invalid code");
        } else {
          setErrorMessage(error)
        }
        setLoading(false)
      });
  };

  const loginType = (values: any) => {
    let v = values.email;
    if (isEmail(v)) {
      setShowotp(false);
      setShowpassword(true);
    } else if(isPhoneNumber(v)) {
      fetchOtp(v);
    }
  };

  const isEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    const phoneRegex2 = /^\d{10}$/;
    const phoneRegex3 = /^\d{12}$/;
    return phoneRegex.test(phoneNumber) || phoneRegex2.test(phoneNumber) || phoneRegex3.test(phoneNumber);
}

  const handleSubmit = (values: any) => {
    if (showpassword) {
      setLoading(true);
      passwordLogin(values);
    }else if (showotp) {
      setLoading(true);
      otpLogin(values);
    } else {
      loginType(values);
    }
  };

  const fetchProfile = (tenant: any, token: any) => {
    request.getWithHeaders(`${Endpoints.userProfile}`, token, tenant.tenantcode)
      .then((response: AxiosResponse) => {
        let { data } = response
        localStorage.setItem("__treda__user__", JSON.stringify(getStorableUser(data)));
        setLoading(false);
        loadHomePage();
      });
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100" data-testid="main-wrapper">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>

                <div className="text-center py-5">
                  <div className="mb-4 mb-md-5">
                    <Link to="/sales" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="50" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="50" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="mb-4">
                    <h5>Data Factory - Login</h5>
                  </div>

                  {errorMessage?.length && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <button type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span>
                    </button>
                    <i className="uil uil-exclamation-octagon me-2"></i> {errorMessage}
                  </div>}

                  <Formik
                    initialValues={credentials}
                    validationSchema={SignInScheme}
                    onSubmit={(values) => handleSubmit(values)}>

                    {({ isSubmitting, errors, touched }) => (
                      <Form>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="email" type="text"
                            className={`form-control ${touched.email && errors.email && "is-invalid"}`}
                            id="input-email"
                            placeholder="Enter email address" data-testid="input-email" />
                          <Label htmlFor="input-email">Email or phone</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-user-alt ${touched.email && errors.email && "text-danger"}`}></i>
                          </div>
                        </div>
                        {showpassword &&
                          <div className="form-floating form-floating-custom mb-3">
                            <Field name="password" type="password"
                              className={`form-control ${touched.password && errors.password && "is-invalid"}`}
                              id="input-password"
                              placeholder="Enter password" data-testid="input-password" />
                            <Label htmlFor="input-password">Password</Label>
                            <div className="form-floating-icon">
                              <i className={`fa fa-lock ${touched.password && errors.password && "text-danger"}`}></i>
                            </div>
                          </div>
                        }

                        {showotp &&
                          <div className="form-floating form-floating-custom mb-3">
                            <Field name="otp" type="password"
                              className={`form-control ${touched.otp && errors.otp && "is-invalid"}`}
                              id="input-password"
                              placeholder="Enter password" data-testid="input-password" />
                            <Label htmlFor="input-password">OTP</Label>
                            <div className="form-floating-icon">
                              <i className={`fa fa-lock ${touched.otp && errors.otp && "text-danger"}`}></i>
                            </div>
                          </div>
                        }

                        <div className="mt-3">
                          <Button color="primary"
                            data-testid="login-button"
                            disabled={isSubmitting && loading}
                            className="btn btn-primary w-100" type="submit">
                            {isSubmitting && loading ? <Spinner size={"sm"} data-testid="loader"></Spinner> : "Log in"}
                          </Button>
                        </div>
                      </Form>
                    )}

                  </Formik>

                  <div className="mt-5 text-center text-muted">
                    <Link to={Urls.auth.resetPassword}>Forgot password?</Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy; {" "}{new Date().getFullYear()} ASIGMA.</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
