export const Endpoints = {
  login: "/auth/login",
  signup: "/auth/signup",
  loginType: "/auth/type",
  loginRedeem: "/auth/redeemcode",
  otp: "/auth/otp",
  otpLogin: "/auth/otp/login",
  partners: "/api/partners",
  programs: "/api/programs",
  users: "/api/users",
  userProfile:"/api/users/profile",
  locations: '/api/locations',
  countries: "/api/countries",
  tenants:"/api/tenants",
  home:"/api/home/index",
  uploaddata: "/api/uploaddata",
  categories: "/api/data/categories",
  tools: "/api/data/tools"
}