import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { allTenants, currentTenant, fullUrl, getStorableUser, setCurrentTenant } from "../../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Endpoints } from "../../../utils/Endpoints";
import { AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";

const TenantsMenu = (props: any) => {
  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const tenants = allTenants();

  useEffect(() => {
    const tenant = currentTenant();
    if (tenant) {
      setUsername(tenant.name);
      setEmail(tenant.email);
      setRole(tenant.category);
      setAvatar(fullUrl(tenant.logo));
    }
  }, [success]);

  const handleSwitch = (id: any) => {
    tenants.map((tenant: any) => {
      if (tenant.id == id) {
        fetchProfile(tenant);
      }
    })
  };

  const request = new ApiCore();

  const fetchProfile = (tenant: any) => {
    toast
    .promise(request.get(`${Endpoints.userProfile}`, {tenant_id: tenant.id}), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          return "";
        },
      },
      error: {
        render({ data }) {
          return data;
        },
      },
    })
    .then((response: AxiosResponse) => {
      setCurrentTenant(tenant);
      let { data } = response
      localStorage.setItem("__treda__user__", JSON.stringify(getStorableUser(data)))
      window.location.reload();
    });
  }

  return (
    <></>
  );
};

export default withTranslation()(withRouter(TenantsMenu));
