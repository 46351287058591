import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Button, Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";

//Import React Router
import { useParams } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Rectangle, Cell, PieChart, Pie } from 'recharts';
import { Loading } from "../../components/common/Loading";
import { Endpoints } from "../../utils/Endpoints";


const Dashboard = () => {
    const { id } = useParams();
    const [tool, setTool] = useState<any>(null);
    const [fields, setFields] = useState<any>([]);
    const [allData, setAllData] = useState<any>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const graphData = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    const pieData = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: { cx: number, cy: number, midAngle: any, innerRadius: any, outerRadius: any, percent: any, index: any }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={12} xl={12}>
                                <Card>
                                    <CardHeader className="justify-content-between d-flex align-items-center">
                                        <CardTitle className="h4">
                                            Dashboard
                                        </CardTitle>

                                    </CardHeader>

                                    <CardBody>
                                        <>
                                            {loadingData && <Loading label="Loading data. Please wait." />}
                                            {!loadingData &&
                                                <Row>
                                                    <Col md={6}>
                                                        <LineChart width={500} height={300} data={graphData}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                                            <YAxis />
                                                            <Tooltip />
                                                            <Legend />
                                                            <Line
                                                                type="monotone"
                                                                dataKey="pv"
                                                                stroke="#8884d8"
                                                                activeDot={{ r: 8 }}
                                                            />
                                                            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                                                        </LineChart>
                                                    </Col>
                                                    <Col md={6}>
                                                        <BarChart
                                                            width={500}
                                                            height={300}
                                                            data={graphData}
                                                            margin={{
                                                                top: 5,
                                                                right: 30,
                                                                left: 20,
                                                                bottom: 5,
                                                            }}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip />
                                                            <Legend />
                                                            <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                                            <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                                                        </BarChart>
                                                    </Col>
                                                    <PieChart width={400} height={400}>
                                                        <Pie
                                                            data={pieData}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={renderCustomizedLabel}
                                                            outerRadius={80}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {pieData.map((entry: any, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                    </PieChart>
                                                </Row>
                                            }
                                        </>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </div>
            </>
        </React.Fragment>
    );
};

export default Dashboard;
