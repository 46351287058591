import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import XModal from "../../../components/common/XModal";
import CreateForm from "../../../components/forms/CreateForm";
import { ToolFields } from "../../../fields/ToolFields";
import BulkUpload from "./upload";
import { SaleUpdateFields } from "../../../fields/SaleUpdateFields";
import { SectionFields } from "../../../fields/SectionFields";
import { SectionFieldFields } from "../../../fields/SectionFieldFields";
import { Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { XTextArea } from "../../../components/common/inputs/XTextArea";
import { XDatePickerField } from "../../../components/common/inputs/XDatePickerField";
import { XSearchableSelect } from "../../../components/common/inputs/XSearchableSelect";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { XRenderField } from "./XRenderField";

const request = new ApiCore();

const ToolDetails = () => {
  const { id } = useParams();
  const [tool, setTool] = useState<any>(null);
  const [fields, setFields] = useState<any>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showSectionDialog, setShowSectionDialog] = useState<boolean>(false);
  const [showMessageDialog, setShowMessageDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showFieldDialog, setShowFieldDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});

  const [initialSectionValues, setInitialSectionValues] = useState<any>({});
  const [initialFieldValues, setInitialFieldValues] = useState<any>({});
  const [initialMessageValues, setInitialMessageValues] = useState<any>({});

  useEffect(() => {
    loadTool();
  }, []);

  const loadTool = () => {
    request
      .get(`${Endpoints.tools}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setTool(data);

        let allFields: { value: any; label: any; }[] = [];
        data.sections.forEach((element: any) => {
          element.fields.forEach((f: any) => {
            allFields.push({
              value: f.ld,
              label: f.label
            })
          })
        });
        setFields(allFields);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdate = (item: any) => {
    loadTool();
  };

  const fieldTypes: ISelectOption[] = [
    { value: 'text', text: 'Text' },
    { value: 'select', text: 'Dropdown' },
    { value: 'date', text: 'Date' },
    { value: 'long text', text: 'Long text' },
    { value: 'checkbox', text: 'Checkbox' },
    { value: 'radio', text: 'Radio' },
    { value: 'country', text: 'Country' },
    { value: 'number', text: 'Number' },
    { value: 'gender', text: 'Gender' },
    { value: 'file', text: 'File' }
  ];

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadTool();
    });
  }

  const handleClick = (record: any, action: string) => {
    if (action == "edit") {
      setInitialValues(record);
      setShowDialog(true);
    } else if (action == "edit_section") {
      setInitialSectionValues(record);
      setShowSectionDialog(true);
    } else if (action == "add_field") {
      setInitialFieldValues({});
      setInitialSectionValues(record);
      setShowFieldDialog(true);
    } else if (action == "delete_section") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/api/tools/${record.id}/sections`, {})
          .then((response: AxiosResponse) => {
            loadTool();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (action == "delete_upload") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/uploads/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadTool();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  const handleSubmitField = (values: any) => {
    const request_body = values;

    if (values.id) {
      request_body.id = values.id;
      toast.promise(request.update(Endpoints.tools + "/" + tool.id + "/sections/" + initialSectionValues.id + "/fields", request_body), {
        pending: "Please wait..",
        error: {
          render({ data }) {
            return data;
          }
        }
      })
        .then((response: AxiosResponse) => {
          loadTool();
        });
    } else {
      toast.promise(request.create(Endpoints.tools + "/" + tool.id + "/sections/" + initialSectionValues.id + "/fields", request_body), {
        pending: "Please wait..",
        error: {
          render({ data }) {
            return data;
          }
        }
      }).then((response: AxiosResponse) => {
        loadTool();
      });
    }
  };

  return (
    <React.Fragment>
      {tool && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Tool"}
              width={"lg"}
              open={showDialog}
              toggle={() => setShowDialog(!showDialog)}
              onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={ToolFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.tools}
                submitButtonLabel={initialValues.id ? "Edit Tool" : "Add Tool"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>
            <XModal title={"Section"}
              width={"lg"}
              open={showSectionDialog}
              toggle={() => setShowSectionDialog(!showSectionDialog)}
              onClose={() => setShowSectionDialog(false)}>

              <CreateForm
                fields={SectionFields}
                initialValues={initialSectionValues}
                submitEndpoint={"/api/data/tools/" + tool.id + "/sections"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowSectionDialog(false)} />

            </XModal>
            <XModal title={"Field"}
              width={"lg"}
              open={showFieldDialog}
              toggle={() => setShowFieldDialog(!showFieldDialog)}
              onClose={() => setShowFieldDialog(false)}>
              <div className="modal-body">
                <Formik
                  initialValues={initialFieldValues}
                  enableReinitialize
                  validateOnBlur
                  onSubmit={(values: any) => handleSubmitField(values)}
                >
                  {({
                    isSubmitting,
                    setFieldValue,
                    values,
                    errors,
                    touched,
                  }) => (
                    <>
                      <Form>
                        <Row>
                          <Col lg={4}>
                            <XSelect
                              key="category"
                              id="category"
                              name={"category"}
                              options={fieldTypes}
                              label={"Category"}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col lg={4}>
                            <XTextBox
                              id="label"
                              type={"text"}
                              name={"label"}
                              label={"Label"}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col lg={4}>
                            <XTextBox
                              id="position"
                              type={"number"}
                              name={"position"}
                              label={"Position"}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col lg={4}>
                            <XCheckbox
                              id="required"
                              name={"required"}
                              label={"Is Required?"}
                              onChange={setFieldValue}
                              checked={values.required}
                              value={"1"} size={{}} />
                          </Col>
                          <Col lg={4}>
                            <XCheckbox
                              id="identifier"
                              name={"identifier"}
                              label={"Is Identifier?"}
                              onChange={setFieldValue}
                              checked={values.identifier}
                              value={"1"} size={{}} />
                          </Col>
                          <Col lg={4}>
                            <XCheckbox
                              id="uniqueIdentifier"
                              name={"uniqueIdentifier"}
                              label={"Is Unique Identifier?"}
                              checked={values.uniqueIdentifier}
                              onChange={setFieldValue}
                              value={"1"} size={{}} />
                          </Col>
                          <Col lg={4}>
                            <XTextBox
                              id="defaultValue"
                              type={"text"}
                              name={"defaultValue"}
                              label={"Default Value"}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col lg={8}>
                            <XSearchableSelect
                              key="dependsOn"
                              id="dependsOn"
                              value={values.dependsOn}
                              name={"dependsOn"}
                              options={fields}
                              label={"Depends On"}
                              onChange={(value: any) => {
                                setFieldValue("dependsOn", value.value);
                              }}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col lg={12}>
                            <XTextBox
                              id="optionList"
                              type={"text"}
                              name={"optionList"}
                              label={"Options (comma listed)"}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col lg={4}>
                            <XTextBox
                              id="conditionalValue"
                              type={"text"}
                              name={"conditionalValue"}
                              label={"Conditional Value"}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col lg={4}>
                            <XCheckbox
                              id="showInGrid"
                              name={"showInGrid"}
                              label={"Show In Grid?"}
                              checked={values.showInGrid}
                              includeTopPadding={true}
                              onChange={setFieldValue}
                              value={"1"} size={{}} />
                          </Col>
                        </Row>
                        <div className="modal-footer">
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </XModal>
            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: tool.id, entityType: "tool" }} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Details
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#" onClick={() => { setInitialValues(tool); setShowDialog(true); }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowSectionDialog(true)}>
                            Add section
                          </DropdownItem>
                          <DropdownItem href={`/tools/${tool.id}/data`}>
                            Fill Form
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowMessageDialog(true)}>
                            View Data
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowUploadDialog(true)}>
                            Download Excel
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={10}>
                          <Row>
                            <Col md={6}>
                              <div className="dt-value">{tool.name}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label"><b>Category</b> {tool.category ? tool.category.name : "N/A"}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label"><b>Is Active?</b> {tool.active ? "Yes" : "No"}</div>
                            </Col>
                          </Row>
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Formik
                initialValues={{}}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmitField(values)}
              >
                {({
                  isSubmitting,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => (
                  <Row>
                    <Col md={12} lg={12} xl={12}>
                      {
                        tool.sections.map((section: any) => {
                          return (
                            <Card>
                              <CardHeader className="justify-content-between d-flex align-items-center">
                                <CardTitle className="h4">
                                  {section.label}
                                </CardTitle>

                                <UncontrolledDropdown className="mt-4 mt-sm-0">
                                  <DropdownToggle tag="a" className="btn btn-light">
                                    <i className="fa fa-ellipsis-v"></i>
                                  </DropdownToggle>

                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem to="#" onClick={() => { handleClick(section, "edit_section") }}>
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem to="#" onClick={() => handleClick(section, "add_field")}>
                                      Add Field
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>


                              </CardHeader>
                              <CardBody>
                                <div className="list-group">
                                  {
                                    section.fields.map((field: any) => {
                                      return (
                                        <div className="list-group-item list-group-item-action flex-column align-items-start" key={field.id}>
                                          <div className="d-flex w-100">
                                            <XRenderField field={field} touched={touched} setFieldValue={setFieldValue} value={""} usePlaceHolders={true} />
                                            &nbsp;&nbsp;<span><a href="#" onClick={() => {
                                              setInitialFieldValues(field);
                                              setInitialSectionValues(section);
                                              setShowFieldDialog(true);
                                            }} className="btn btn-edit">&nbsp;&nbsp;<i className="fa fa-edit"></i></a></span>
                                            &nbsp;&nbsp;<span><a href="#" onClick={() => {
                                              setInitialFieldValues(field);
                                              setInitialSectionValues(section);
                                              setShowFieldDialog(true);
                                            }} className="btn btn-drop"><i className="fa fa-trash red"></i></a></span>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </CardBody>
                            </Card>
                          )
                        })
                      }

                    </Col>
                  </Row>
                )}
              </Formik>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ToolDetails;
