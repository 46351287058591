import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { PartnerFields } from "../../fields/PartnerFields";
import BulkUpload from "./upload";
import { Settings } from "../../utils/Settings";
import { SaleUpdateFields } from "../../fields/SaleUpdateFields";
import { ProgramFields } from "../../fields/ProgramFields";

const request = new ApiCore();

const PartnerDetails = () => {
  const { id } = useParams();
  const [partner, setPartner] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showProgramDialog, setShowProgramDialog] = useState<boolean>(false);
  const [showMessageDialog, setShowMessageDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});

  const [initialProgramValues, setInitialProgramValues] = useState<any>({});
  const [initialMessageValues, setInitialMessageValues] = useState<any>({});

  useEffect(() => {
    loadPartner();
  }, []);

  const loadPartner = () => {
    request
      .get(`${Endpoints.partners}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setPartner(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdate = (item: any) => {
    loadPartner();
  };

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadPartner();
    });
  }

  const handleClick = (record: any, action: string) => {
    if (action == "edit") {
      setInitialValues(record);
      setShowDialog(true);
    }else if (action == "edit_Program") {
      setInitialProgramValues(record);
      setShowProgramDialog(true);
    } else if (action == "delete_upload") {
      if (window.confirm("Do you want to delete this record?")) {
        request
        .delete(`/uploads/${record.id}`, {})
        .then((response: AxiosResponse) => {
          loadPartner();
        })
        .catch((error) => {
          console.error(error);
        });
      }
    }
  }
  
  return (
    <React.Fragment>
      {partner && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Partner"}
                  width={"lg"}
                  open={showDialog}
                  toggle={() => setShowDialog(!showDialog)}
                  onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={PartnerFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.partners}
                submitButtonLabel={initialValues.id ? "Edit Partner" : "Add Partner"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>
            <XModal title={"Program"}
                  width={"lg"}
                  open={showProgramDialog}
                  toggle={() => setShowProgramDialog(!showDialog)}
                  onClose={() => setShowProgramDialog(false)}>

              <CreateForm
                fields={ProgramFields}
                initialValues={initialProgramValues}
                submitEndpoint={`/api/partners/${partner.id}/program`}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowProgramDialog(false)} />

            </XModal>

            <XModal title={"Upload documents"}
                  width={"lg"}
                  open={showUploadDialog}
                  toggle={() => setShowUploadDialog(!showUploadDialog)}
                  onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{entityId: partner.id, entityType: "partner"}} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                  <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Partner Details
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => {setInitialValues(partner); setShowDialog(true);}}>
                        Edit
                      </DropdownItem>
                      <DropdownItem to="#" onClick={() => setShowProgramDialog(true)}>
                        Add Program
                      </DropdownItem>
                      <DropdownItem to="#" onClick={() => setShowUploadDialog(true)}>
                        Upload documents
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={10}>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Name</div>
                              <div className="dt-value">{partner.name}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Sector</div>
                              <div className="dt-value">{partner.sector}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Phone</div>
                              <div className="dt-value">{partner.telephone}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Email</div>
                              <div className="dt-value">{partner.email}</div>
                            </Col>
                          </Row>
                          <hr/>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br/>
            <Container fluid>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <Card>
                    <CardBody>
                      <b>PROGRAMS</b><br/>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th>Start Date</th><th>End Date</th></tr>
                        </thead>
                        <tbody>
                          {partner.programs.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={`/programs/${record.id}`}>{record.name}</a></td>
                              <td>
                                 {record.startDate ? formattedDate(record.startDate) : record.startDate}
                              </td>
                              <td>
                              {record.endDate ? formattedDate(record.endDate) : record.endDate}
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                <Card>
                    <CardBody>
                      <b>UPLOADS</b><br/>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th></th></tr>
                        </thead>
                        <tbody>
                          {partner.uploads.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={Settings.getFile(record.path, "download")}>{record.name && record.name.length > 0 ? record.name : record.path}</a></td>
                              <td>
                                 <a href="#" onClick={ () => handleClick(record, "delete_upload")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default PartnerDetails;
